import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, Tooltip, Checkbox, Popover, useTheme } from "@mui/material";
import moment from 'moment';
import 'moment/locale/fr';

import SimpleSelect from "../../component/SelectDropDown/SimpleSelect";
import AddUpdateMatchPopup from "./AddUpdateMatchPopup";
import { Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle } from "../../component/Popup/Popup";
import ApiClient from "../../service/ApiClient";
import {useParams} from "react-router-dom";
import ReactGA from "react-ga4";

import '../../assets/Calendrier.scss'

export default function Calendrier(props) {
    const theme = useTheme();
    const params = useParams();
    const [seasons, setSeasons] = useState(null);
    const [teams, setTeams] = useState(null);
    const [calendar, setCalendar] = useState(null);
    const [actualSeason, setActualSeason] = useState(null);
    const [actualMatch, setActualMatch] = useState(null);
    const [nextMatch, setNextMatch] = useState(null);
    const [actualId, setActualId] = useState(null);
    const [showPopup, setShowPopup] = useState("");
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [editMatch, setEditMatch] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const styles = {
        addMatch: {
            position: "fixed",
            bottom: "75px",
            right: "50px",
            alignItems: "center",
            display: "block",
            '& button': {
                backgroundColor: "transparent",
                border: "none"
            },
            '& i': {
                color: theme.palette.colors.green,
                fontSize: "50px"
            }
        },
        topBottom : {
            position: "fixed",
            bottom: "75px",
            right: "0px",
            alignItems: "center",
            display: "block",
            '&:hover': {
                opacity: "75%"
            },
            '& button': {
                backgroundColor: "transparent",
                border: "none"
            },
            '& i': {
                opacity: "75%",
                backgroundColor: theme.palette.colors.green,
                borderRadius: "50%",
                color: theme.palette.colors.whiteBox,
                fontSize: "40px"
            }
        },
        monthBox: {
            borderBottom: "black solid 2px",
            fontSize: "30px",
            fontWeight: "normal",
            margin: "0",
            '& span': {
                fontWeight: "bold",
                color: theme.palette.colors.green,
            }
        },
        leftPart: {
            width: "50px",
            height: "100px",
            backgroundColor: theme.palette.colors.green,
            webkitTransform: "skewX(-10.5deg)",
            msTransform: "skewX(-10.5deg)",
            oTransform: "skewX(-10.5deg)",
            transform: "skewX(-10.5deg)",
            transformOrigin: "top right",
        },
        actionButton: {
            color: theme.palette.colors.green,
            border: "none",
            backgroundColor: "rgba(255, 255, 128, 0)",
        },
        cancelButton: {
            color: "red",
            border: "none",
            backgroundColor: "rgba(255, 255, 128, 0)",
        },
        anchor_sm: {
            display: "block",
            position: "relative",
            top: "-220px",
            visibility: "hidden"
        },
        anchor_md: {
            display: "block",
            position: "relative",
            top: "-180px",
            visibility: "hidden"
        }
    };

    window.addEventListener('scroll', (event) => {
        setScrollPosition(window.pageYOffset);
    });

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        (async () => {
            try {
                await loadData(true, null);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [params.team]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    async function loadData(type, season) {
        let idSeason = season;
        let result = await ApiClient.instance.getSeason();
        setSeasons(result.data);
        if (type) {
            for (let i = 0; i < result.data.length; i++) {
                if (result.data[i].defaultSeason) {
                    idSeason = result.data[i].value;
                    setActualSeason(idSeason);
                    break;
                }
            }
        }
        result = await ApiClient.instance.getCalendar(params.team, idSeason);
        setCalendar(result.data);
        result = await ApiClient.instance.getTeams();
        setTeams(result.data);
        result = await ApiClient.instance.getNextMatch(params.team);
        setNextMatch(result.data);
    }

    async function addMatch(match) {
        try {
            match.date = new moment(match.date).format("YYYY-MM-DD HH:mm:ss");
            await ApiClient.instance.createMatch(params.team, match);
            props.createNotification("success", {title: "Succès", message: "Match créé", timeout: 3000});
            setShowPopup("");
            await loadData(false, actualSeason);
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Match non créé", timeout: 3000});
        }
    }

    async function updateMatch(match) {
        try {
            match.date = new moment(match.date).format("YYYY-MM-DD HH:mm:ss");
            await ApiClient.instance.updateMatch(params.team, match);
            props.createNotification("success", {title: "Succès", message: "Match mis à jour", timeout: 3000});
            setShowPopup("");
            await loadData(false, actualSeason);
        } catch (e) {
            props.createNotification("error", {title: "Erreur", message: "Match non mis à jour", timeout: 3000});
        }
    }

    function closePopup(type) {
        if (type === "PopupModify")
            setActualMatch(null);
        else
            setShowPopup("")
    }

    async function handleSeason(idSeason) {
        setActualSeason(idSeason);
        await loadData(false, idSeason);
    }

    function buildEditAction() {
        return (
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <Grid container direction={"column"}>
                    <Grid className={"action-item"} item onClick={() => {
                        setEditMatch(true);
                        handleClose();
                    }}>
                        <Box>Editer le score</Box>
                    </Grid>
                    <Grid className={"separator"} item/>
                    <Grid className={"action-item"} item onClick={() => {
                        setShowPopup("updateMatch")
                        handleClose();
                    }}>
                        <Box>Editer le match</Box>
                    </Grid>
                </Grid>


            </Popover>
        )
    }

    function buildCalendar(match, date) {
        let encodedUriTeam1 = encodeURI(process.env.REACT_APP_API_URL + match.team1.logo).replaceAll("'", "\\'");
        let encodedUriTeam2 = encodeURI(process.env.REACT_APP_API_URL + match.team2.logo).replaceAll("'", "\\'");
        if (window.innerWidth > 960) {
            return (
                <Grid key={match.id} container item style={{overflow: "hidden", margin: "10px 0", border: "solid 1px #c7c7c7"}} alignItems={"center"}>
                    {nextMatch.length !== 0 && nextMatch[0].id === match.id && <div sx={styles.anchor_md} id={"matchavenir"}/>}
                    <Grid item>
                        <Box sx={styles.leftPart}/>
                    </Grid>
                    <Grid container direction={"column"} md={3} item justifyContent={"center"}>
                        <Grid item>
                            <Typography
                                variant={"subtitle1"}><b>{date.format("D ") + date.format("MMM").charAt(0).toUpperCase() + date.format("MMM").slice(1) + date.format(" YYYY")}</b> - {date.format("H")}h{date.format("mm")}
                            </Typography>
                        </Grid>
                        <Grid item><Typography variant={"subtitle1"}>{match.competition}</Typography></Grid>
                    </Grid>
                    <Grid container item xs style={{marginRight: "1rem"}} alignItems={"center"}>
                        <Grid container item xs justifyContent={"flex-end"} style={{marginRight: "10px"}}>
                            <Grid item>
                                <Typography variant={"subtitle1"}>{match.team1.name}</Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{
                            background: "url(" + encodedUriTeam1 + ")", backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat', height: "100px"}} item xs={2}/>
                    </Grid>

                    { (!editMatch ? true : match.id !== (actualMatch === null ? -1 : actualMatch.id)) &&
                    <Grid item>
                        <Typography variant={"h6"} style={{textAlign: "center"}}>{match.team1.score === null ? " " : match.team1.score} - {match.team2.score === null ? " " : match.team2.score}</Typography>
                        {match.team1.penalty !== null && match.team2.penalty !== null &&
                            <Typography variant={"h5"} style={{textAlign: "center"}}>{match.team1.penalty} tab {match.team2.penalty}</Typography>
                        }
                        {((match.team1.forfait && !match.team2.forfait) || (!match.team1.forfait && match.team2.forfait)) &&
                        <Typography variant={"h5"} style={{textAlign: "center"}}>Forfait</Typography>
                        }
                    </Grid>}
                    {(editMatch ? match.id === (actualMatch === null ? -1 : actualMatch.id) : false) &&
                        <Grid item xs={2} container direction={"column"}>
                            <Grid container item>
                                <Grid item xs>
                                    <input style={{width: "100%"}} type={"number"} pattern={"[0-9]*"} value={actualMatch.team1.score} defaultValue={(actualMatch.team1.score === null ? 0 : actualMatch.team1.score)} onChange={(event) => {
                                        let tmp = actualMatch;
                                        tmp.team1.score = event.target.value
                                        if (event.target.validity.valid)
                                            setActualMatch({...actualMatch, ...tmp});
                                    }}/>
                                </Grid>
                                <Grid item>-</Grid>
                                <Grid item xs>
                                    <input style={{width: "100%"}} type={"number"} pattern={"[0-9]*"}  value={actualMatch.team2.score} defaultValue={(actualMatch.team2.score === null ? 0 : actualMatch.team2.score)} onChange={(event) => {
                                        let tmp = actualMatch;
                                        tmp.team2.score = event.target.value
                                        if (event.target.validity.valid)
                                            setActualMatch({...actualMatch, ...tmp});
                                    }}/>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={"center"} alignItems={"center"} item>
                                <Grid item>
                                    <Checkbox checked={actualMatch.team1.forfait} onChange={(event) => {
                                        let tmp = actualMatch;

                                        tmp.team1.forfait = event.target.checked;
                                        if (event.target.checked) {
                                            tmp.team1.score = 0;
                                            tmp.team2.score = 3;
                                            tmp.team2.forfait = false;
                                        } else {
                                            tmp.team1.score = 0;
                                            tmp.team2.score = 0;
                                        }
                                        setActualMatch({...actualMatch, ...tmp});
                                    }}
                                        sx={{
                                        '&.Mui-checked': {
                                            color: theme.palette.colors.green,
                                        },
                                    }} />
                                </Grid>
                                <Grid item>
                                    <b>Forfait</b>
                                </Grid>
                                <Grid item>
                                    <Checkbox checked={actualMatch.team2.forfait} onChange={(event) => {
                                        let tmp = actualMatch;

                                        tmp.team2.forfait = event.target.checked;
                                        if (event.target.checked) {
                                            tmp.team1.score = 3;
                                            tmp.team2.score = 0;
                                            tmp.team1.forfait = false;
                                        } else {
                                            tmp.team1.score = 0;
                                            tmp.team2.score = 0;
                                        }
                                        setActualMatch({...actualMatch, ...tmp});
                                    }} sx={{
                                        '&.Mui-checked': {
                                            color: theme.palette.colors.green,
                                        },
                                    }} />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={"center"} alignItems={"center"} item>
                                <Grid item>
                                    <b>Arrangé</b>
                                </Grid>
                                <Grid item>
                                    <Checkbox checked={actualMatch.team2.forfait && actualMatch.team1.forfait} onChange={(event) => {
                                        let tmp = actualMatch;

                                        tmp.team2.forfait = event.target.checked;
                                        tmp.team1.forfait = event.target.checked;
                                        setActualMatch({...actualMatch, ...tmp});
                                    }} sx={{
                                        '&.Mui-checked': {
                                            color: theme.palette.colors.green,
                                        },
                                    }} />
                                </Grid>
                            </Grid>
                            {actualMatch.team1.score !== null && actualMatch.team2.score !== null
                            && parseInt(actualMatch.team1.score) === parseInt(actualMatch.team2.score) &&
                            <Grid container item>
                                <Grid item xs>
                                    <input style={{width: "100%"}} type={"number"} pattern={"[0-9]*"}
                                           defaultValue={(actualMatch.team1.penalty === null ? "" : actualMatch.team1.penalty)}
                                           onChange={(event) => {
                                               let tmp = actualMatch;
                                               tmp.team1.penalty = event.target.value
                                               if (event.target.validity.valid)
                                                   setActualMatch({...actualMatch, tmp});
                                           }}/>
                                </Grid>
                                <Grid item>-</Grid>
                                <Grid item xs>
                                    <input style={{width: "100%"}} type={"number"} pattern={"[0-9]*"}
                                           defaultValue={(actualMatch.team2.penalty === null ? "" : actualMatch.team2.penalty)}
                                           onChange={(event) => {
                                               let tmp = actualMatch;
                                               tmp.team2.penalty = event.target.value
                                               if (event.target.validity.valid)
                                                   setActualMatch({...actualMatch, tmp});
                                           }}/>
                                </Grid>
                            </Grid>
                            }
                        </Grid>
                    }

                    <Grid container item xs style={{marginLeft: "1rem"}} alignItems={"center"}>
                        <Grid style={{
                            background: "url(" + encodedUriTeam2 + ")", backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat', height: "100px"}} item xs={2}/>
                        <Grid container item xs justifyContent={"flex-start"} style={{marginLeft: "10px"}}>
                            <Grid item>
                                <Typography variant={"subtitle1"}>{match.team2.name}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {props.isAdmin && (!editMatch ? true : match.id !== (actualMatch === null ? -1 : actualMatch.id)) &&
                    <Box style={{position: "relative", top: 0, right: 0}}>
                        <button style={styles.actionButton}
                                onClick={() => {
                                    setConfirmPopup(true);
                                    setActualId(match.id);
                                }}><i className="material-icons">delete</i></button>
                        <button style={styles.actionButton}
                                onClick={(e) => {
                                    setActualMatch(match);
                                    handleClick(e);
                                }}><i className="material-icons" style={{width: "24px"}}>more_vertical</i></button>
                        {buildEditAction()}
                    </Box>
                    }

                    {props.isAdmin && editMatch && match.id === (actualMatch === null ? -1 : actualMatch.id) &&
                    <Box style={{position: "relative", top: 0, right: 0}}>
                        <button style={styles.cancelButton}
                                onClick={async() => {
                                    setActualMatch(null);
                                    setEditMatch(false);
                                    await loadData(false, actualSeason);
                                }}><i className="material-icons">clear</i></button>
                        <button style={styles.actionButton}
                                onClick={async() => {
                                    let tmpMatch = actualMatch;
                                    tmpMatch.date = new moment(tmpMatch.date).format("YYYY-MM-DD HH:mm:ss")
                                    if (tmpMatch.team1.score === null)
                                        tmpMatch.team1.score = 0;
                                    if (tmpMatch.team2.score === null)
                                        tmpMatch.team2.score = 0;
                                    if (parseInt(actualMatch.team1.score) !== parseInt(actualMatch.team2.score) || (tmpMatch.team1.penalty === "" && tmpMatch.team2.penalty === "")) {
                                        tmpMatch.team1.penalty = null;
                                        tmpMatch.team2.penalty = null;
                                    }
                                    try {
                                        await ApiClient.instance.updateMatch(params.team, tmpMatch);
                                        await ApiClient.instance.createOrUpdateRankProno(params.team, tmpMatch.season);
                                        props.createNotification("success", {title: "Succès", message: "Match mis à jour", timeout: 3000});
                                        await loadData(false, actualSeason);
                                    } catch (e) {
                                        props.createNotification("error", {title: "Erreur", message: "Match non mis à jour", timeout: 3000});
                                    }

                                    setActualMatch(null);
                                    setEditMatch(false);
                                }}><i className="material-icons">done</i></button>
                    </Box>}
                </Grid>
            );
        } else {
            return (
                <Grid key={match.id} container item style={{overflow: "hidden", margin: "10px 0", border: "solid 1px #c7c7c7"}} alignItems={"center"}>
                    {nextMatch.length !== 0 && nextMatch[0].id === match.id && <div style={styles.anchor_sm} id={"matchavenir"}/>}
                    <Grid item>
                        <Box sx={styles.leftPart} style={{height: "250px"}}/>
                    </Grid>
                    <Grid item container direction={"column"} alignItems={"center"} xs>
                        {props.isAdmin && (!editMatch ? true : match.id !== (actualMatch === null ? -1 : actualMatch.id)) &&
                        <Grid container item xs justifyContent={"flex-end"}>
                            <Grid item>
                                <button style={styles.actionButton}
                                        onClick={() => {
                                            setConfirmPopup(true);
                                            setActualId(match.id);
                                        }}><i className="material-icons">delete</i></button>
                            </Grid>
                            <Grid item>
                                <button style={styles.actionButton}
                                        onClick={(e) => {
                                            setActualMatch(match);
                                            handleClick(e);
                                        }}><i className="material-icons" style={{width: "24px"}}>more_vertical</i></button>
                                {buildEditAction()}
                            </Grid>
                        </Grid>}

                        {props.isAdmin && editMatch && match.id === (actualMatch === null ? -1 : actualMatch.id) &&
                        <Grid container item xs justifyContent={"flex-end"}>
                            <Grid item>
                                <button style={styles.cancelButton}
                                        onClick={async() => {
                                            setActualMatch(null);
                                            setEditMatch(false);
                                            await loadData(false, actualSeason);
                                        }}><i className="material-icons">clear</i></button>
                            </Grid>
                            <Grid item>
                                <button style={styles.actionButton}
                                        onClick={async() => {
                                            let tmpMatch = actualMatch;
                                            tmpMatch.date = new moment(tmpMatch.date).format("YYYY-MM-DD HH:mm:ss")
                                            if (tmpMatch.team1.score === null)
                                                tmpMatch.team1.score = 0;
                                            if (tmpMatch.team2.score === null)
                                                tmpMatch.team2.score = 0;
                                            if (parseInt(actualMatch.team1.score) !== parseInt(actualMatch.team2.score) || (tmpMatch.team1.penalty === "" && tmpMatch.team2.penalty === "")) {
                                                tmpMatch.team1.penalty = null;
                                                tmpMatch.team2.penalty = null;
                                            }

                                            try {
                                                await ApiClient.instance.updateMatch(params.team, tmpMatch);
                                                await ApiClient.instance.createOrUpdateRankProno(params.team, tmpMatch.season);
                                                props.createNotification("success", {title: "Succès", message: "Match mis à jour", timeout: 3000});
                                                await loadData(false, actualSeason);
                                                setActualMatch(null);
                                                setEditMatch(false);
                                            } catch (e) {
                                                props.createNotification("error", {title: "Erreur", message: "Match non mis à jour", timeout: 3000});
                                            }
                                        }}><i className="material-icons">done</i></button>
                            </Grid>
                        </Grid>}
                        <Grid container item justifyContent={"center"} xs>
                            <Grid container direction={"column"} item alignItems={"center"}>
                                <Grid item>
                                    <Typography variant={"subtitle1"}>
                                        <b>{date.format("D ") + date.format("MMM").charAt(0).toUpperCase() + date.format("MMM").slice(1) + date.format(" YYYY")}</b> - {date.format("H")}h{date.format("mm")}
                                    </Typography>
                                </Grid>
                                <Grid item><Typography variant={"subtitle1"}>{match.competition}</Typography></Grid>
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent={"center"} xs alignItems={"center"}>
                            <Grid container item direction={"column"} alignItems={"center"} xs>
                                <Grid container item justifyContent={"center"}>
                                    <Grid style={{background: "url(" + encodedUriTeam1 + ")", backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat', height: "100px"}} item xs={4}/>
                                </Grid>
                                <Grid item><Typography variant={"subtitle1"}>{match.team1.name}</Typography></Grid>
                            </Grid>
                                {(!editMatch ? true : match.id !== (actualMatch === null ? -1 : actualMatch.id)) &&
                                <Grid item>
                                    <Typography variant={"h6"} style={{textAlign: "center"}}>{match.team1.score === null ? " " : match.team1.score} - {match.team2.score === null ? " " : match.team2.score}</Typography>
                                    {match.team1.penalty !== null && match.team2.penalty !== null &&
                                    <Typography variant={"h5"} style={{textAlign: "center"}}>{match.team1.penalty} tab {match.team2.penalty}</Typography>
                                    }
                                    {((match.team1.forfait && !match.team2.forfait) || (!match.team1.forfait && match.team2.forfait)) &&
                                        <Typography variant={"h5"} style={{textAlign: "center"}}>Forfait</Typography>
                                    }
                                </Grid>}
                                {(editMatch ? match.id === (actualMatch === null ? -1 : actualMatch.id) : false) &&
                                    <Grid container item xs={3}>
                                        <Grid item xs>
                                            <input style={{width: "100%"}} type={"number"} pattern={"[0-9]*"} value={actualMatch.team1.score} defaultValue={(actualMatch.team1.score === null ? 0 : actualMatch.team1.score)} onChange={(event) => {
                                                let tmp = actualMatch;
                                                tmp.team1.score = event.target.value
                                                if (event.target.validity.valid)
                                                    setActualMatch({...actualMatch, ...tmp});
                                            }}/>
                                        </Grid>
                                        <Grid item>-</Grid>
                                        <Grid item xs>
                                            <input style={{width: "100%"}} type={"number"} pattern={"[0-9]*"} value={actualMatch.team2.score} defaultValue={(actualMatch.team2.score === null ? 0 : actualMatch.team2.score)} onChange={(event) => {
                                                let tmp = actualMatch;
                                                tmp.team2.score = event.target.value
                                                if (event.target.validity.valid)
                                                    setActualMatch({...actualMatch, ...tmp});
                                            }}/>
                                        </Grid>
                                        <Grid container item justifyContent={"center"} alignItems={"center"} sx={{paddingTop: "1rem"}}>
                                            <Grid item>
                                                <Typography variant={"subtitle1"}><b>Forfait</b></Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container justifyContent={"center"} alignItems={"center"} item>
                                            <Grid item>
                                                <Checkbox checked={actualMatch.team1.forfait} onChange={(event) => {
                                                    let tmp = actualMatch;

                                                    tmp.team1.forfait = event.target.checked;
                                                    if (event.target.checked) {
                                                        tmp.team1.score = 3;
                                                        tmp.team2.score = 0;
                                                        tmp.team2.forfait = false;
                                                    } else {
                                                        tmp.team1.score = 0;
                                                        tmp.team2.score = 0;
                                                    }
                                                    setActualMatch({...actualMatch, ...tmp});
                                                }}
                                                          sx={{
                                                              '&.Mui-checked': {
                                                                  color: theme.palette.colors.green,
                                                              },
                                                          }} />
                                            </Grid>

                                            <Grid item>
                                                <Checkbox checked={actualMatch.team2.forfait} onChange={(event) => {
                                                    let tmp = actualMatch;

                                                    tmp.team2.forfait = event.target.checked;
                                                    if (event.target.checked) {
                                                        tmp.team1.score = 0;
                                                        tmp.team2.score = 3;
                                                        tmp.team1.forfait = false;
                                                    } else {
                                                        tmp.team1.score = 0;
                                                        tmp.team2.score = 0;
                                                    }
                                                    setActualMatch({...actualMatch, ...tmp});
                                                }} sx={{
                                                    '&.Mui-checked': {
                                                        color: theme.palette.colors.green,
                                                    },
                                                }} />
                                            </Grid>
                                        </Grid>

                                        <Grid container justifyContent={"center"} alignItems={"center"} item>
                                            <Grid item>
                                                <b>Arrangé</b>
                                            </Grid>
                                            <Grid item>
                                                <Checkbox checked={actualMatch.team2.forfait && actualMatch.team1.forfait} onChange={(event) => {
                                                        let tmp = actualMatch;

                                                        tmp.team2.forfait = event.target.checked;
                                                        tmp.team1.forfait = event.target.checked;
                                                        setActualMatch({...actualMatch, ...tmp});
                                                    }} sx={{
                                                        '&.Mui-checked': {
                                                            color: theme.palette.colors.green,
                                                        },
                                                }} />
                                </Grid>
                            </Grid>

                                        {actualMatch.team1.score !== null && actualMatch.team2.score !== null
                                        && parseInt(actualMatch.team1.score) === parseInt(actualMatch.team2.score) &&
                                        <Grid container item>
                                            <Grid item xs>
                                                <input style={{width: "100%"}} type={"number"} pattern={"[0-9]*"}
                                                       defaultValue={(actualMatch.team1.penalty === null ? "" : actualMatch.team1.penalty)}
                                                       onChange={(event) => {
                                                           let tmp = actualMatch;
                                                           tmp.team1.penalty = event.target.value
                                                           if (event.target.validity.valid)
                                                               setActualMatch({...actualMatch, ...tmp});
                                                       }}/>
                                            </Grid>
                                            <Grid item>-</Grid>
                                            <Grid item xs>
                                                <input style={{width: "100%"}} type={"number"} pattern={"[0-9]*"}
                                                       defaultValue={(actualMatch.team2.penalty === null ? "" : actualMatch.team2.penalty)}
                                                       onChange={(event) => {
                                                           let tmp = actualMatch;
                                                           tmp.team2.penalty = event.target.value
                                                           if (event.target.validity.valid)
                                                               setActualMatch({...actualMatch, ...tmp});
                                                       }}/>
                                            </Grid>
                                        </Grid>
                                        }
                                    </Grid>
                                }
                            <Grid container item direction={"column"} xs alignItems={"center"} justifyContent={"center"}>
                                <Grid container item justifyContent={"center"}><Grid style={{
                                    background: "url(" + encodedUriTeam2 + ")", backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat', height: "100px"}} item xs={4}/></Grid>
                                <Grid item><Typography variant={"subtitle1"}>{match.team2.name}</Typography></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    }

    function buildMonthBox() {
        let actualBoxMonth = null;
        let actualMatchMonth = null;
        let dateTmp = null;
        let listMatch = [];
        let monthBox = [];

        for (let i = 0; i < calendar.length; i++) {
            dateTmp = moment(calendar[i].date);
            actualBoxMonth = dateTmp.format("MMMM YYYY");
            actualMatchMonth = dateTmp.format("MMMM YYYY");
            listMatch = [];
            while (i < calendar.length) {
                dateTmp = moment(calendar[i].date);
                actualMatchMonth = dateTmp.format("MMMM YYYY");
                if (actualBoxMonth !== actualMatchMonth)
                    break;
                listMatch.push(buildCalendar(calendar[i], dateTmp));
                i++;
            }
            i--;
            monthBox.push(
                <Grid container item direction={"column"} key={i}>
                    <Grid item>
                        <h2 sx={styles.monthBox}>
                            {actualBoxMonth.split(" ")[0].charAt(0).toUpperCase() + actualBoxMonth.split(" ")[0].slice(1)}
                            <span> {actualBoxMonth.split(" ")[1]}</span>
                        </h2>
                    </Grid>
                    {listMatch}
                </Grid>);
        }
        return (monthBox);
    }

    if (seasons == null || actualSeason == null || calendar == null || nextMatch == null)
        return (<Box/>);
    return (<Grid container direction={"column"}>
        <Grid container item justifyContent={"flex-end"}>
            <Grid item>
                <SimpleSelect data={seasons} onChange={handleSeason} actualIndex={actualSeason} textLabel={"Saison"}/>
            </Grid>
        </Grid>
        <Grid container direction={"column"} item>
            {buildMonthBox()}
            <div sx={styles.anchor_sm} id={"derniermatch"}/>
        </Grid>
        {showPopup === "addMatch" && <AddUpdateMatchPopup close={closePopup} submit={addMatch} category={params.team} seasonData={seasons} teams={teams} match={null}/>}
        {showPopup === "updateMatch" && <AddUpdateMatchPopup close={closePopup} submit={updateMatch} category={params.team} seasonData={seasons} teams={teams}
                                                             match={actualMatch} matchId={actualId}/>}
        {confirmPopup &&
        <Popup close={() => {
            setConfirmPopup(false);
        }}>
            <PopupHeader>
                <PopupTitle>Supprimer un match</PopupTitle>
            </PopupHeader>
            <PopupBody>
                <p>Voulez-vous vraiment supprimer ce match ?</p>
            </PopupBody>
            <PopupFooter dismiss={"Non"} close={() => {
                setConfirmPopup(false);
            }}>
                <button className={"btn btn-primary"} onClick={async() => {
                    try {
                        await ApiClient.instance.deleteMatch(params.team, actualId);
                        props.createNotification("success", {title: "Succès", message: "Match supprimé", timeout: 3000});
                        setConfirmPopup(false);
                        await loadData(false, actualSeason);
                    } catch (e) {
                        props.createNotification("error", {title: "Erreur", message: "Match non supprimé", timeout: 3000});
                    }
                }}>Oui
                </button>
            </PopupFooter>
        </Popup>
        }

        <Box sx={styles.topBottom}>
            <a href={scrollPosition !== 0 ? "#top" : (nextMatch.length === 0 ? "#derniermatch" : "#matchavenir")}>
                <Tooltip title={scrollPosition !== 0 ? "Vers le haut de la page" : (nextMatch.length === 0 ? "Vers le dernier match" : "Vers le prochain match")}>
                <i className="material-icons">{scrollPosition !== 0 ? "keyboard_arrow_up" : "keyboard_arrow_down"}</i>
                </Tooltip>
            </a>
        </Box>

        {props.isAdmin &&
        <Box sx={styles.addMatch}>
            <button className={"no-button"} onClick={() => {
                setShowPopup("addMatch");
            }}>
                <i className="material-icons">add_circle</i>
            </button>
        </Box>}
    </Grid>);
}