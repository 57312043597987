import React, { useState, useEffect } from "react";
import {Popup, PopupBody, PopupFooter, PopupHeader, PopupTitle} from "../../component/Popup/Popup";
import { FormControlLabel, FormGroup, Grid, TextField, Typography, Checkbox } from "@mui/material";

import ApiClient from "../../service/ApiClient";

export default function UpdateClassementPopup(props) {
    const [newRank, setNewRank] = useState(null);
    const [notification, setNotification] = useState("");
    const [autoUpdate, setAutoUpdate] = useState(true);
    const [linkRank, setLinkRank] = useState("");

    useEffect(() => {
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error);
            }
        })();

    }, []);

    async function loadData() {
        try {
            let result = await ApiClient.instance.getLinkRank(props.category);
            setLinkRank(result.data.link);
        } catch (error) {
            console.log(error);
        }
    }

    function handleSubmit() {
        setNotification("");
        if (newRank === null){
            setNotification("Nombre d'équipes incorrectes doit être égale à " + props.rank.length)
            return;
        }

        if (newRank.length !== props.rank.length) {
            setNotification("Nombre d'équipes incorrectes doit être égale à " + props.rank.length)
            return;
        }
        let tmpRank = [];
        let tmp = {};
        let rowRank = [];
        let finalRank = [];
        for (let i = 0; i < newRank.length; i++) {
            tmpRank = newRank[i].replace(/\s/g, ' ').split(' ');
            tmp.pointStat = tmpRank.splice(tmpRank.length - 10, 10);
            tmp.pointStat.splice(5, 1);
            tmp.pointStat.splice(7, 1);
            tmp.rank = parseInt(tmpRank.splice(0,1).join());
            tmp.team = tmpRank;
            rowRank = tmp.pointStat.map(function(item){
                return parseInt(item);
            });
            if (getIdFromNameTeam(tmp.team) === null) {
                setNotification("L'équipe " + tmp.team + " n'a pas été trouvé");
                return;
            }
            rowRank.unshift(getIdFromNameTeam(tmp.team));
            rowRank.unshift(tmp.rank);
            finalRank.push(rowRank);
        }
        props.update(finalRank);
    }

    function handleSubmitLinkAuto() {
        setNotification("");
        if (linkRank === ""){
            setNotification("Lien invalide")
            return;
        }

        let url = new URL(linkRank);
        let data = {
            id_competition: url.searchParams.get("id") !== null ? url.searchParams.get("id") : url.searchParams.get("competition"),
            phase: url.searchParams.get("phase") !== null ? url.searchParams.get("phase") : url.searchParams.get("stage"),
            poule: url.searchParams.get("poule") !== null ? url.searchParams.get("poule") : url.searchParams.get("group"),
            category: props.category,
            link: linkRank,
            season: props.actualSeason
        }

        props.updateLink(data);
    }

    function getIdFromNameTeam(name) {
        name.sort(function(a, b){return b.length - a.length});
        let re =  new RegExp("\\w*(" + name[0] + ")\\w*", "i");
        let team_id = null;
        let teamFound = [];
        

        for (let i = 0; i < props.rank.length; i++) {
            if (re.test(props.rank[i].Team.Name)) {
                teamFound.push(props.rank[i].Team)
                team_id = props.rank[i].Team.TeamId; 
            }
        }

        if (teamFound.length > 1) {
            
            if (name.length > 1) {
                re =  new RegExp("\\w*(" + name[1] + ")\\w*", "i");
            }
            
            for(let i = 0; i < teamFound.length; i++) {
                if (re.test(teamFound[i].Name)) {
                    team_id = teamFound[i].TeamId;
                }
            }
        }

        return team_id;
    }

    return (
            <Popup size={"large"} close={props.close}>
                <PopupHeader>
                    <PopupTitle>Mettre à jour le classement</PopupTitle>
                </PopupHeader>

                <PopupBody>
                    <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"}>
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={autoUpdate} onChange={(event) => setAutoUpdate(event.target.checked)}/>} label="Mise à jour automatique" />
                            </FormGroup>
                        </Grid>

                        { autoUpdate &&
                            <Grid container direction={"column"} xs>
                            <Grid item>
                                <h6 className={"w-100 mb-2"}>Lien du classement (district22)</h6>
                            </Grid>

                            <Grid container item xs={6} justifyContent={"center"}>
                                <Grid item xs={6}>
                                    <TextField fullWidth
                                        sx={{whiteSpace: 'nowrap'}}
                                        id="outlined-multiline-static"
                                        rows={1}
                                        value={linkRank}
                                        onChange={(event) => setLinkRank(event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>}

                        { !autoUpdate &&
                            <Grid container direction={"column"} xs>
                            <Grid item>
                                <h6 className={"w-100 mb-2"}>Mise à jour du classement manuel</h6>
                            </Grid>

                            <Grid container item xs={6} justifyContent={"center"}>
                                <Grid item xs={6}>
                                    <TextField fullWidth
                                        sx={{whiteSpace: 'nowrap'}}
                                        id="outlined-multiline-static"
                                        multiline
                                        rows={12}
                                        onChange={(event) => setNewRank(event.target.value.split(/\r*\n/))}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>}
                    </Grid>
                    <div className="text-danger">{notification}</div>
                </PopupBody>

                <PopupFooter dismiss={"Annuler"} close={props.close}>
                    { !autoUpdate && 
                        <input className={"btn btn-primary"} type={"submit"} value={"Valider"}
                           onClick={() => handleSubmit()}/>
                    }

                    { autoUpdate && 
                        <input className={"btn btn-primary"} type={"submit"} value={"Valider"}
                           onClick={() => handleSubmitLinkAuto()}/>
                    }
                </PopupFooter>
            </Popup>
        );
}